html{
  overflow-x:hidden;
  overflow-y:scroll;
  -webkit-overflow-scrolling:touch
}

body{
  font-family:'Lato', sans-serif;
  font-size:16px;
  overflow-x:hidden
}

.totop{
  display:inline-block;
  position:fixed;
  width:50px;
  height:50px;
  right:30px;
  bottom:30px;
  background:#fff url(/arrow-up_x2.png) 0 0 no-repeat;
  background-size:cover;
  border:1px solid #fff;
  border-radius:50%
}

.totop:focus,*:active{
  outline:0
}

.mlab{
  position:absolute;
  right:0;
  padding:20px;
  font-weight:bold;
  border-top-left-radius:10px;
  border-bottom-left-radius:10px;
  display:inline-block;
  min-width:170px;
  border:1px solid #fff;
  border-right:0
}

.mlab a{
  color:#fff
}

.mlabel1.fixed1{
  position:fixed;
  top:120px !important
}

.mlabel2.fixed2{
  position:fixed;
  top:220px !important
}

.mlabel1{
  top:600px;
  background-color:#0097f0;
  z-index:20
}

.mlabel1 img{
  width:45px;
  margin-right:10px;
  float:left
}

.mlabel2{
  top:700px;
  background-color:#015f67;
  z-index:20
}

.mlabel2 img{
  width:45px;
  margin-right:10px;
  float:left
}

#vollbild{
  background-size:cover;
  -moz-background-size:cover;
  -webkit-background-size:cover;
  -o-background-size:cover;
  background-repeat:no-repeat;
  background-position:center center;
  display:block;
  width:100%;
  position:relative;
  height:500px
}

#vollbild .newestnews{
  margin:0;
  position:absolute;
  top:50%;
  width:100%;
  transform:translateY(-50%);
  color:#fff
}

@media (max-width: 767px){
  #vollbild .newestnews{
    padding:15px
  }
}

#vollbild .btn-onenews{
  margin-top:16px;
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px
}

@font-face{
  font-family:'Archivo Black';

  font-style:normal;

  font-weight:400;

  src:local("Archivo Black Regular"),local("ArchivoBlack-Regular"),url(/static/fonts/archivo-black-v17-latin-regular.ttf) format("truetype")
}

@font-face{
  font-family:'Lato';

  font-style:normal;

  font-weight:400;

  src:local("Lato Regular"),local("Lato-Regular"),url(/static/fonts/lato-v23-latin-regular.ttf) format("truetype")
}

h1{
  font-family:'Archivo Black', sans-serif;
  font-size:3vw;
  line-height:3vw;
  display:inline-block;
  margin:0
}

h2,.h2{
  font-family:'Lato', sans-serif;
  font-size:30px;
  font-weight:normal;
  text-transform:uppercase;
  color:#015f67;
  margin:0;
  margin-bottom:20px
}

h3{
  font-family:'Archivo Black', sans-serif;
  font-size:26px;
  line-height:26px;
  display:inline-block;
  margin:0;
  margin-bottom:20px
}

h4{
  font-family:'Lato', sans-serif;
  font-size:24px;
  font-weight:normal;
  color:#0097f0;
  margin:0;
  margin-bottom:20px
}

.bg-white{
  background-color:#fff
}

.bg-gray-1{
  background-color:#e8e8e8
}

.ankertarget{
  height:0;
  position:relative;
  top:-150px
}

.btn.btn-info{
  padding:5px 30px;
  background-color:#0097f0;
  background-image:unset;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px
}

.btn.btn-info:hover{
  background-color:#fff;
  border:1px solid #0097f0;
  color:#0097f0
}

#footer{
  background-color:#015f67;
  padding:50px 0;
  color:#fff;
  font-size:16px
}

@media (max-width: 767px){
  #footer{
    padding:15px 0;
    font-size:13px
  }
}

@media (min-width: 970px) and (max-width: 1182px){
  #footer{
    font-size:14px
  }
}

#footer strong{
  font-size:18px
}

#footer a{
  color:#fff
}

#footer ul{
  padding-left:0;
  float:right
}

#footer ul li{
  float:left;
  padding:0 10px;
  margin:0 10px
}

#footer ul li:first-child{
  list-style:none
}

#footer ul li:last-child{
  margin-right:0;
  padding-right:0
}

@media (max-width: 767px){
  #footer ul{
    padding-top:25px;
    font-size:14px
  }
}

#footer .social a{
  margin-left:20px
}

#members{
  padding-top:95px
}

#members .container.login{
  margin-top:60px;
  margin-bottom:60px
}

#members .container.lostpass{
  margin-top:60px;
  margin-bottom:60px
}

#members .login .btn.btn-login,#members .login .btn.btn-lostpw,#members .lostpass .btn.btn-login,#members .lostpass .btn.btn-lostpw{
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px
}

.modal-content{
  border-radius:0
}

.modal-content .container{
  width:100% !important
}

.modal-content .modal-body input[type='text']:focus,.modal-content .modal-body input[type='email']:focus,.modal-content .modal-body input[type='number']:focus,.modal-content .modal-body input[type='text']:focus{
  border-radius:0
}

.modal-content .modal-body .form-control{
  background-image:none;
  border-radius:0;
  box-shadow:unset;
  font-size:18px;
  height:38px;
  line-height:1.42857;
  padding:7px 15px;
  width:100%
}

.modal-content .modal-body .form-group{
  margin-bottom:15px
}

.modal-content .modal-body #bemerkungen{
  height:180px
}

.modal-content .modal-body ::-webkit-input-placeholder{
  color:#cccccc
}

.modal-content .modal-body :-moz-placeholder{
  color:#cccccc
}

.modal-content .modal-body input:focus:-moz-placeholder{
  color:#cccccc
}

.modal-content .modal-body ::-moz-placeholder{
  color:#cccccc
}

.modal-content .modal-body input:focus::-moz-placeholder{
  color:#cccccc
}

.modal-content .modal-body :-ms-input-placeholder{
  color:#cccccc
}

.modal-content .modal-body input:focus:-ms-input-placeholder{
  color:#cccccc
}

.modal-content .modal-footer .btn{
  background-image:unset;
  border-radius:0;
  padding:7px 45px;
  font-size:20px
}

.navbar-fixed-top{
  background-color:transparent;
  padding:0 15px;
  border-width:0;
  margin-bottom:0
}

.navbar-fixed-top .navbar-right{
  margin-right:0
}

.navbar-fixed-top .navbar-toggle .icon-bar{
  width:40px;
  height:4px;
  border-radius:0;
  background-color:#fff;
  margin-bottom:6px
}

.navbar-fixed-top li.dropdown:hover>.dropdown-menu{
  display:block
}

.navbar-fixed-top img.weiss{
  display:block;
  margin-top:-14px
}

.navbar-fixed-top img.bunt{
  display:none;
  margin-top:-14px
}

.navbar-fixed-top .btn.btn-login{
  margin-top:16px;
  text-transform:uppercase;
  padding:5px 30px;
  background-color:transparent;
  border:1px solid #fff;
  color:#fff;
  font-size:16px
}

.navbar-fixed-top .navbar-nav>li>a{
  text-shadow:0
}

.navbar-fixed-top ul.nav li.dropdown:hover ul.dropdown-menu{
  margin-top:0px
}

.navbar-fixed-top .navbar-right .dropdown-menu{
  right:auto;
  border:0;
  border-radius:0
}

.navbar-fixed-top #navbar .dropdown-menu{
  font-size:16px
}

.navbar-fixed-top #navbar .dropdown-menu>li>a{
  padding-left:16px
}

.navbar-fixed-top .top1{
  height:30px;
  font-size:18px;
  color:#fff
}

.navbar-fixed-top .top1 a{
  color:#fff
}

.navbar-fixed-top .top2{
  min-height:65px;
  font-size:18px;
  text-transform:uppercase;
  display:block
}

.navbar-fixed-top .top2 .navbar-nav{
  margin-top:16px
}

.navbar-fixed-top .top2 li.active a{
  text-decoration:underline
}

.navbar-fixed-top .top2 li a{
  color:#fff;
  font-size:15px
}

.navbar-fixed-top .top2 li>a:hover{
  text-decoration:underline;
  background-color:transparent
}

.navbar-fixed-top .top2 li.dropdown li a{
  color:#333 !important;
  text-decoration:none !important;
  font-size:15px
}

.navbar-fixed-top .top2.mobile{
  display:none
}

.navbar-fixed-top.colored{
  background-color:#fff;
  box-shadow:0 6px 12px rgba(0,0,0,0.175)
}

.navbar-fixed-top.colored .navbar-toggle .icon-bar{
  background-color:#0097f0
}

.navbar-fixed-top.colored img.weiss{
  display:none
}

.navbar-fixed-top.colored img.bunt{
  display:block
}

.navbar-fixed-top.colored .btn.btn-login{
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff
}

.navbar-fixed-top.colored .top1{
  color:#333
}

.navbar-fixed-top.colored .top1 a{
  color:#333
}

.navbar-fixed-top.colored .top2{
  display:block
}

.navbar-fixed-top.colored .top2 li.active a{
  text-decoration:underline;
  color:#0097f0
}

.navbar-fixed-top.colored .top2 li a{
  color:#333
}

.navbar-fixed-top.colored .top2 li>a:hover{
  text-decoration:underline;
  color:#0097f0;
  background-color:transparent
}

.navbar-fixed-top.colored .top2.mobile{
  display:none
}

@media (max-width: 1199px){
  .navbar-header{
    float:none
  }

  .navbar-left,.navbar-right{
    float:none !important
  }

  .navbar-toggle{
    display:block;
    margin-right:0
  }

  .navbar-collapse{
    border-top:1px solid transparent;
    box-shadow:inset 0 1px 0 rgba(255,255,255,0.1)
  }

  .navbar-fixed-top{
    top:0;
    border-width:0 0 1px
  }

  .navbar-collapse.collapse{
    display:none !important
  }

  .navbar-nav{
    float:none !important;
    margin-top:7.5px
  }

  .navbar-nav>li{
    float:none
  }

  .navbar-nav>li>a{
    padding-top:10px;
    padding-bottom:10px
  }

  .collapse.in{
    display:block !important
  }

  nav .top2{
    display:none !important;
    font-size:14px
  }

  nav .top2 li a{
    padding:5px
  }

  nav .top2 .navbar-nav{
    margin-top:17px
  }

  nav .top2.mobile{
    display:block !important
  }

  nav .top2.mobile .dropdown-menu{
    position:relative;
    top:unset;
    left:unset;
    float:unset;
    display:block
  }

  nav .top2.mobile .dropdown-menu li{
    padding-left:20px
  }
}

@media (max-width: 767px){
  nav .top2 .media-heading a{
    padding-left:0
  }

  nav .top2 .yamm-content{
    width:auto;
    padding-left:42px
  }

  nav .top2 li a{
    font-size:20px;
    font-weight:500;
    padding-top:9px;
    padding-bottom:9px;
    padding-left:25px
  }

  nav .top2>.row{
    background-color:#fff;
    box-shadow:0 6px 12px rgba(0,0,0,0.175);
    min-height:75px
  }

  nav .top2 .navbar-collapse{
    padding-top:40px
  }

  nav .top2 li a.start{
    border-top:1px solid rgba(0,0,0,0.175)
  }

  nav .top2 li a.start,nav .top2 li a.jump{
    padding-left:5px;
    font-weight:700
  }

  .navbar-fixed-top.colored img.bunt{
    width:180px;
    margin-top:5px
  }
}

.area-aktuelles.detail,.area-newsereignisse.detail{
  padding-top:140px;
  padding-bottom:50px
}

.area-aktuelles.detail .btn-javaback,.area-newsereignisse.detail .btn-javaback{
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px;
  float:right
}

.galtbild,.galbild{
  border:1px solid #015f67;
  margin-bottom:20px
}

.area-aktuelles .col-sm-3{
  display:flex
}

.area-aktuelles .aktuell{
  border:1px solid #0097f0;
  margin-top:50px;
  background-color:#fff
}

.area-aktuelles .aktuell .infos{
  padding:15px
}

.area-aktuelles .date{
  font-size:14px;
  margin:0
}

.area-aktuelles .title{
  color:#015f67;
  font-weight:bold
}

.area-aktuelles .more{
  display:block;
  width:30px;
  height:30px;
  position:absolute;
  bottom:0;
  right:25px
}

.area-newsereignisse .datekreis{
  background-color:#fff;
  margin-top:50px;
  border-radius:50%;
  border:2px solid #aaa;
  padding:5px;
  width:105px;
  height:105px
}

.area-newsereignisse .datekreis .datekreisinnen{
  color:#fff;
  background-color:#0097f0;
  text-align:center;
  border-radius:50%;
  width:100%;
  height:100%
}

.area-newsereignisse .datekreis .datekreisinnen p{
  margin:0;
  padding:0
}

.area-newsereignisse .datekreis .datekreisinnen .tag{
  font-size:30px;
  font-weight:bold
}

.area-newsereignisse .datekreis .datekreisinnen .mon{
  font-size:25px;
  margin:-11px 0
}

.area-newsereignisse .datekreis .datekreisinnen .jar{
  margin-top:0
}

.area-newsereignisse .newsbanner{
  border:2px solid #aaa;
  position:relative;
  padding:15px;
  margin-top:50px
}

.area-newsereignisse .newsbanner .row{
  display:flex
}

.area-newsereignisse .newsbanner .date{
  font-size:14px;
  margin:0
}

@media (max-width: 767px){
  .area-newsereignisse .newsbanner{
    margin-top:10px;
    margin-bottom:30px
  }
}

.area-newsereignisse .title{
  color:#015f67;
  font-weight:bold
}

.area-newsereignisse .more{
  display:block;
  width:30px;
  height:30px;
  position:absolute;
  bottom:-10px;
  right:15px
}

.pagination{
  padding-top:40px
}

.pagination>li>a,.pagination>li>span{
  margin-left:0;
  color:#0097f0;
  background-color:#fff;
  border:1px solid #0097f0;
  margin:0 2px
}

.pagination>li>a:hover,.pagination>li>span:hover,.pagination>li>a:focus,.pagination>li>span:focus{
  background-color:#e8e8e8 !important;
  color:#333
}

.pagination>.disabled>span,.pagination>.disabled>span:hover,.pagination>.disabled>span:focus,.pagination>.disabled>a,.pagination>.disabled>a:hover,.pagination>.disabled>a:focus{
  color:#e8e8e8;
  cursor:not-allowed;
  background-color:#fff;
  border-color:#e8e8e8
}

.pagination .aktuell a{
  background-color:#0097f0 !important;
  color:#fff;
  border:1px solid #0097f0 !important
}

.area-ansprechpartner{
  padding-bottom:50px
}

.area-ansprechpartner .abbox{
  background-color:#e8e8e8;
  border-radius:6px;
  margin:0;
  padding:0;
  overflow:hidden;
  border:1px solid #e8e8e8;
  margin-bottom:30px;
  min-height:330px
}

.area-ansprechpartner .abbox .bild{
  padding:0
}

.area-ansprechpartner .abbox .text{
  padding:20px
}

.area-ansprechpartner .abbox .text .position{
  font-weight:700;
  font-size:20px;
  color:#0097f0
}

.area-ansprechpartner .abbox .text .job{
  padding:0;
  margin:0
}

.area-ansprechpartner .abbox .text .name{
  font-weight:700
}

.bluebuttons .btn-blue{
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px;
  margin-bottom:15px;
  margin-right:20px
}

.bluebuttons .btn-blue:hover{
  background-color:#fff;
  color:#0097f0
}

.area-galerie{
  padding-top:50px;
  padding-bottom:50px
}

.area-galerie .fancybox{
  margin-bottom:30px;
  display:inline-block
}

@media (max-width: 767px){
  section.area-map{
    padding:0 !important;
    padding-bottom:30px !important
  }
}

section.area-map .container-fluid{
  padding-left:0;
  padding-right:0
}

section.area-map .container.maphead{
  margin-bottom:50px
}

@media (max-width: 767px){
  section.area-map .container.maphead{
    margin-bottom:20px
  }
}

.area-headerbanner{
  padding-top:95px
}

.area-infomobilinfos{
  padding-bottom:50px
}

.area-infomobilinfos #googleinfomobilkarte{
  width:100%
}

.area-infomobilinfos .infobox{
  font-size:20px
}

.area-infomobilinfos .infobox span.imk,.area-infomobilinfos .infobox span.imke{
  width:100px
}

.area-infomobilinfos .infobox .downloadbox{
  margin-top:50px
}

.area-infomobilinfos .infobox i.fa{
  padding-right:20px
}

.area-kachelslider .kachelhead{
  padding-bottom:35px
}

@media (max-width: 767px){
  .area-kachelslider .kachelhead{
    padding-bottom:0
  }
}

.area-kachelslider .flexslider{
  background-color:transparent
}

.area-kachelslider .flexslider .overlay{
  position:relative;
  width:88%
}

@media (max-width: 767px){
  .area-kachelslider .flexslider .overlay{
    top:10px;
    width:300px
  }
}

.area-kachelslider .flexslider .overlaytxt{
  padding:0 15px;
  color:#fff;
  display:block;
  font-size:24px;
  position:absolute;
  bottom:0;
  width:100%;
  font-weight:700
}

@media (max-width: 767px){
  .area-kachelslider .flexslider .overlaytxt{
    padding:10px 30px;
    display:inline-block;
    left:0;
    width:100%;
    font-size:30px;
    position:relative
  }
}

.area-kachelslider .flexslider .overlaylink{
  position:relative
}

.area-kachelslider .flexslider .overlaylink img{
  position:absolute;
  width:40px;
  height:40px;
  bottom:15px;
  right:15px
}

.area-kachelslider .flex-control-nav.flex-control-paging{
  display:none
}

.area-logoblock img{
  margin:15px 0;
  border:3px solid #ffffff
}

.area-mgu,.area-mgd{
  padding-top:140px;
  padding-bottom:50px
}

.area-mgu .btn-javaback,.area-mgd .btn-javaback{
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px;
  float:right
}

.area-mgu .mgbox{
  margin-bottom:30px;
  background-color:#e8e8e8;
  border-radius:5px;
  min-height:365px
}

.area-mgu .mglogo{
  border:2px solid #aaa;
  background-color:#fff;
  border-radius:5px;
  position:relative;
  padding:20px
}

.area-mgu .mgtext{
  padding:20px
}

.area-mgu .mgtext p.title{
  color:#015f67;
  font-weight:bold
}

.area-mgu .mgtext p.url a{
  color:#0097f0;
  text-decoration:underline
}

.area-mgu .more{
  display:block;
  width:30px;
  height:30px;
  position:absolute;
  bottom:-3px;
  right:6px
}

.area-mgd .mglogo{
  border:2px solid #aaa;
  background-color:#fff;
  border-radius:5px;
  position:relative;
  padding:20px
}

.area-mgd .mgtext{
  padding:0 20px
}

.area-mgd .mgtext p.title{
  color:#015f67;
  font-weight:bold
}

.area-mgd .mgtext p.url a{
  color:#0097f0;
  text-decoration:underline
}

.area-mgd .bakker img{
  display:block;
  width:30px;
  height:30px;
  transform:rotate(180deg);
  position:absolute;
  top:-1px
}

.area-mgd .bakker a{
  margin-left:20px
}

.area-partneraussagen{
  margin-bottom:100px
}

.area-partneraussagen .row.flex{
  display:flex
}

@media (max-width: 767px){
  .area-partneraussagen .row.flex{
    display:block
  }
}

.area-partneraussagen .flexslider{
  position:absolute;
  top:0;
  background-color:transparent
}

.area-partneraussagen .flexslider .parslibak{
  position:relative;
  top:0
}

.area-partneraussagen .flexslider .slide{
  color:#fff;
  padding:50px;
  text-align:center;
  font-size:20px
}

.area-partneraussagen .flexslider .slide .qo,.area-partneraussagen .flexslider .slide .qu{
  width:100%
}

.area-partneraussagen .flexslider .slide .qo{
  text-align:left
}

.area-partneraussagen .flexslider .slide .qu{
  text-align:right
}

.area-partneraussagen .flexslider .slides img{
  width:auto !important;
  display:inline-block;
  margin-bottom:30px
}

.area-partneraussagen .flexslider .slides .dummykreis{
  width:250px;
  height:250px;
  display:inline-block;
  margin-bottom:30px;
  border:1px solid #fff;
  border-radius:50%;
  overflow:hidden
}

@media (max-width: 767px){
  .area-partneraussagen .flexslider .slides .dummykreis{
    display:none
  }
}

.area-partneraussagen .memberbox{
  position:absolute;
  bottom:-50px;
  left:-50px;
  border-top:3px solid #0097f0;
  background-color:#e8e8e8;
  padding:30px
}

@media (max-width: 767px){
  .area-partneraussagen .memberbox{
    display:none
  }
}

.area-partneraussagen .memberbox .btn-memberform{
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px;
  float:right
}

.area-partneraussagen .mainteaser{
  padding-top:100px
}

.area-partneraussagen .flex-direction-nav a.flex-next,.area-partneraussagen .flex-direction-nav a.flex-prev{
  display:none
}

.area-partneraussagen .flex-control-nav{
  bottom:0px;
  z-index:10
}

.area-partneraussagen .flex-control-paging li a.flex-active{
  background:#fff
}

.area-partneraussagen .flex-control-paging li a{
  background:transparent;
  border:1px solid #fff
}

.area-partnerteaser{
  padding-top:140px;
  padding-bottom:50px
}

.area-partnerteaser .pabox{
  margin-bottom:30px;
  background-color:#fff;
  border-radius:5px
}

.area-partnerteaser .palogo{
  border:2px solid #aaa;
  background-color:#fff;
  border-radius:5px;
  position:relative;
  padding:5px
}

.area-partnerteaser .patext{
  padding:5px 20px 20px 20px
}

.area-Teaser .teabo{
  margin-bottom:30px
}

.area-Teaser .overlay{
  position:absolute;
  width:100%;
  background-color:rgba(255,255,255,0.7);
  display:block;
  height:20%;
  bottom:0;
  padding:5px 15px
}

.area-Teaser .overlaytxt{
  display:block;
  position:absolute;
  color:#333;
  width:80%
}

@media (max-width: 767px){
  .area-Teaser .overlaytxt{
    padding:10px 30px;
    display:inline-block;
    left:0;
    width:100%;
    position:relative
  }
}

.area-Teaser .overlaytxt p{
  padding:0
}

.area-Teaser .overlaylink img{
  position:absolute;
  width:40px;
  height:40px;
  bottom:10px;
  right:25px
}

@media (max-width: 767px){
  .area-Teaser .overlaylink img{
    display:none
  }
}

.textbereich .btn-asp{
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px;
  margin-right:30px
}

.textbereich ul li:before{
  content:"\f058";
  font-family:'Font Awesome 5 Free';
  position:relative;
  font-size:30px;
  margin-left:-33px;
  top:8px;
  padding-right:10px;
  color:#0097f0
}

.textbereich ul li{
  list-style-type:none;
  margin-top:-8px
}

.area-videos{
  padding-top:50px;
  padding-bottom:50px
}

.area-videos .responsive-video iframe{
  position:absolute;
  top:0;
  left:0;
  width:100%
}

.area-videos .responsive-video{
  position:relative;
  padding-bottom:56.25%;
  padding-top:0px;
  height:0;
  overflow:hidden
}

.area-videos .mediathek h4{
  display:block;
  color:unset;
  font-weight:bold;
  font-size:22px;
  height:40px
}

.area-videos .mediathek .videocol{
  margin-bottom:50px
}

@media (max-width: 991px){
  .area-videos .mediathek h4{
    display:block;
    color:unset;
    font-weight:bold;
    font-size:22px;
    height:unset
  }

  .area-videos .mediathek .videocol{
    margin-bottom:20px;
    margin-top:20px
  }
}

section.beitrittsform{
  padding-top:50px;
  padding-bottom:30px
}

section.beitrittsform .fa-info-circle{
  color:#015f67;
  cursor:pointer
}

section.beitrittsform span.info{
  display:none;
  padding:10px;
  margin:10px 0px;
  background-color:#fff;
  border-radius:5px
}

section.beitrittsform .gebdat{
  position:absolute;
  left:-10000px
}

section.beitrittsform .col-sm-12.h2{
  text-transform:unset
}

section.beitrittsform input[type='text']:focus{
  border-radius:0
}

section.beitrittsform .form-control{
  background-image:none;
  box-shadow:unset;
  font-size:18px;
  height:38px;
  line-height:1.42857;
  padding:7px 15px;
  width:100%
}

section.beitrittsform ::-webkit-input-placeholder{
  color:#cccccc
}

section.beitrittsform :-moz-placeholder{
  color:#cccccc
}

section.beitrittsform input:focus:-moz-placeholder{
  color:#cccccc
}

section.beitrittsform ::-moz-placeholder{
  color:#cccccc
}

section.beitrittsform input:focus::-moz-placeholder{
  color:#cccccc
}

section.beitrittsform :-ms-input-placeholder{
  color:#cccccc
}

section.beitrittsform input:focus:-ms-input-placeholder{
  color:#cccccc
}

section.beitrittsform i.fa{
  padding-right:20px
}

section.beitrittsform .btn.btn-senden{
  margin-top:16px;
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px
}

section.beitrittsform .funkyradio div{
  clear:both;
  overflow:hidden
}

section.beitrittsform .funkyradio label{
  width:100%;
  border-radius:3px;
  border:1px solid #e8e8e8;
  font-weight:normal;
  background-color:#fff
}

section.beitrittsform .funkyradio input[type="radio"]:empty,section.beitrittsform .funkyradio input[type="checkbox"]:empty{
  display:none
}

section.beitrittsform .funkyradio input[type="radio"]:empty ~ label,section.beitrittsform .funkyradio input[type="checkbox"]:empty ~ label{
  position:relative;
  padding-left:48px;
  font-size:14px;
  height:50px;
  line-height:15px;
  margin-bottom:1em;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

section.beitrittsform .funkyradio input[type="radio"]:empty ~ label b,section.beitrittsform .funkyradio input[type="checkbox"]:empty ~ label b{
  color:#015f67
}

section.beitrittsform .funkyradio input[type="radio"]:empty ~ label:before,section.beitrittsform .funkyradio input[type="checkbox"]:empty ~ label:before{
  position:absolute;
  display:block;
  top:0;
  bottom:0;
  left:0;
  content:'';
  width:2.5em;
  background:#aaa;
  border-radius:3px 0 0 3px
}

section.beitrittsform .funkyradio input[type="radio"]:hover:not(:checked) ~ label,section.beitrittsform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label{
  color:#888
}

section.beitrittsform .funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,section.beitrittsform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before{
  content:'\2714';
  text-indent:.9em;
  color:#C2C2C2;
  padding-top:15px
}

section.beitrittsform .funkyradio input[type="radio"]:checked ~ label,section.beitrittsform .funkyradio input[type="checkbox"]:checked ~ label{
  color:#777
}

section.beitrittsform .funkyradio input[type="radio"]:checked ~ label:before,section.beitrittsform .funkyradio input[type="checkbox"]:checked ~ label:before{
  content:'\2714';
  text-indent:.9em;
  color:#333;
  background-color:#ccc;
  padding-top:15px
}

section.beitrittsform .funkyradio input[type="radio"]:focus ~ label:before,section.beitrittsform .funkyradio input[type="checkbox"]:focus ~ label:before{
  box-shadow:0 0 0 3px #999
}

section.beitrittsform .funkyradio .funkyradio-info input[type="radio"]:checked ~ label:before,section.beitrittsform .funkyradio .funkyradio-info input[type="checkbox"]:checked ~ label:before{
  color:#fff;
  background-color:#0097f0
}

section#error{
  padding-top:50px;
  padding-bottom:30px
}

section#error .message{
  padding-bottom:20px
}

section#error .code{
  color:#F0F0F0;
  font-size:170px;
  line-height:1;
  font-weight:bold
}

section#error input[type='text']:focus,section#error input[type='email']:focus,section#error input[type='number']:focus,section#error textarea:focus{
  background-color:#fff
}

section#error input[type='text']:focus{
  border-radius:0
}

section#error .form-control{
  background-image:none;
  border-radius:0;
  box-shadow:unset;
  font-size:18px;
  height:38px;
  line-height:1.42857;
  padding:7px 15px;
  width:100%
}

section#error .form-group{
  margin:30px 0 45px 0
}

section#error ::-webkit-input-placeholder{
  color:#cccccc
}

section#error :-moz-placeholder{
  color:#cccccc
}

section#error input:focus:-moz-placeholder{
  color:#cccccc
}

section#error ::-moz-placeholder{
  color:#cccccc
}

section#error input:focus::-moz-placeholder{
  color:#cccccc
}

section#error :-ms-input-placeholder{
  color:#cccccc
}

section#error input:focus:-ms-input-placeholder{
  color:#cccccc
}

section#error .btn{
  background-image:unset;
  border-radius:0;
  color:#fff;
  padding:7px 45px;
  font-size:20px
}

.btn.btn-senden.wpf{
  margin-top:16px;
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px
}

section.kontaktform,section.whitepaperform{
  padding-top:50px;
  padding-bottom:50px
}

section.kontaktform input[type='text']:focus,section.whitepaperform input[type='text']:focus{
  border-radius:0
}

section.kontaktform .gebdat,section.whitepaperform .gebdat{
  position:absolute;
  left:-10000px
}

section.kontaktform .form-control,section.whitepaperform .form-control{
  background-image:none;
  box-shadow:unset;
  font-size:18px;
  height:38px;
  line-height:1.42857;
  padding:7px 15px;
  width:100%
}

section.kontaktform textarea,section.whitepaperform textarea{
  height:200px !important
}

section.kontaktform ::-webkit-input-placeholder,section.whitepaperform ::-webkit-input-placeholder{
  color:#cccccc
}

section.kontaktform :-moz-placeholder,section.whitepaperform :-moz-placeholder{
  color:#cccccc
}

section.kontaktform input:focus:-moz-placeholder,section.whitepaperform input:focus:-moz-placeholder{
  color:#cccccc
}

section.kontaktform ::-moz-placeholder,section.whitepaperform ::-moz-placeholder{
  color:#cccccc
}

section.kontaktform input:focus::-moz-placeholder,section.whitepaperform input:focus::-moz-placeholder{
  color:#cccccc
}

section.kontaktform :-ms-input-placeholder,section.whitepaperform :-ms-input-placeholder{
  color:#cccccc
}

section.kontaktform input:focus:-ms-input-placeholder,section.whitepaperform input:focus:-ms-input-placeholder{
  color:#cccccc
}

section.kontaktform .btn.btn-senden,section.whitepaperform .btn.btn-senden{
  margin-top:16px;
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px
}

section.kontaktform .funkyradio div,section.whitepaperform .funkyradio div{
  clear:both;
  overflow:hidden
}

section.kontaktform .funkyradio label,section.whitepaperform .funkyradio label{
  width:100%;
  border-radius:3px;
  border:1px solid #e8e8e8;
  font-weight:normal;
  background-color:#fff
}

section.kontaktform .funkyradio input[type="radio"]:empty,section.kontaktform .funkyradio input[type="checkbox"]:empty,section.whitepaperform .funkyradio input[type="radio"]:empty,section.whitepaperform .funkyradio input[type="checkbox"]:empty{
  display:none
}

section.kontaktform .funkyradio input[type="radio"]:empty ~ label,section.kontaktform .funkyradio input[type="checkbox"]:empty ~ label,section.whitepaperform .funkyradio input[type="radio"]:empty ~ label,section.whitepaperform .funkyradio input[type="checkbox"]:empty ~ label{
  position:relative;
  padding-left:48px;
  font-size:14px;
  height:50px;
  line-height:15px;
  margin-bottom:1em;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

section.kontaktform .funkyradio input[type="radio"]:empty ~ label b,section.kontaktform .funkyradio input[type="checkbox"]:empty ~ label b,section.whitepaperform .funkyradio input[type="radio"]:empty ~ label b,section.whitepaperform .funkyradio input[type="checkbox"]:empty ~ label b{
  color:#015f67
}

section.kontaktform .funkyradio input[type="radio"]:empty ~ label:before,section.kontaktform .funkyradio input[type="checkbox"]:empty ~ label:before,section.whitepaperform .funkyradio input[type="radio"]:empty ~ label:before,section.whitepaperform .funkyradio input[type="checkbox"]:empty ~ label:before{
  position:absolute;
  display:block;
  top:0;
  bottom:0;
  left:0;
  content:'';
  width:2.5em;
  background:#aaa;
  border-radius:3px 0 0 3px
}

section.kontaktform .funkyradio input[type="radio"]:hover:not(:checked) ~ label,section.kontaktform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label,section.whitepaperform .funkyradio input[type="radio"]:hover:not(:checked) ~ label,section.whitepaperform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label{
  color:#888
}

section.kontaktform .funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,section.kontaktform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before,section.whitepaperform .funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,section.whitepaperform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before{
  content:'\2714';
  text-indent:.9em;
  color:#C2C2C2;
  padding-top:15px
}

section.kontaktform .funkyradio input[type="radio"]:checked ~ label,section.kontaktform .funkyradio input[type="checkbox"]:checked ~ label,section.whitepaperform .funkyradio input[type="radio"]:checked ~ label,section.whitepaperform .funkyradio input[type="checkbox"]:checked ~ label{
  color:#777
}

section.kontaktform .funkyradio input[type="radio"]:checked ~ label:before,section.kontaktform .funkyradio input[type="checkbox"]:checked ~ label:before,section.whitepaperform .funkyradio input[type="radio"]:checked ~ label:before,section.whitepaperform .funkyradio input[type="checkbox"]:checked ~ label:before{
  content:'\2714';
  text-indent:.9em;
  color:#333;
  background-color:#ccc;
  padding-top:15px
}

section.kontaktform .funkyradio input[type="radio"]:focus ~ label:before,section.kontaktform .funkyradio input[type="checkbox"]:focus ~ label:before,section.whitepaperform .funkyradio input[type="radio"]:focus ~ label:before,section.whitepaperform .funkyradio input[type="checkbox"]:focus ~ label:before{
  box-shadow:0 0 0 3px #999
}

section.kontaktform .funkyradio .funkyradio-info input[type="radio"]:checked ~ label:before,section.kontaktform .funkyradio .funkyradio-info input[type="checkbox"]:checked ~ label:before,section.whitepaperform .funkyradio .funkyradio-info input[type="radio"]:checked ~ label:before,section.whitepaperform .funkyradio .funkyradio-info input[type="checkbox"]:checked ~ label:before{
  color:#fff;
  background-color:#0097f0
}

section.kontaktform .output,section.whitepaperform .output{
  font-size:50px;
  color:#0097f0
}

section.produktsuche{
  padding-top:50px;
  padding-bottom:50px;
  background-color:#e8e8e8
}

section.produktsuche select{
  -moz-appearance:none;
  -webkit-appearance:none;
  appearance:none;
  border:1px solid #0097f0;
  width:100%;
  height:40px;
  padding-left:10px;
  border-radius:5px;
  outline:none;
  cursor:pointer;
  position:relative;
  background-image:url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E"),linear-gradient(to bottom, #fff 0%, #e5e5e5 100%);
  background-repeat:no-repeat, repeat;
  background-position:right .7em top 50%, 0 0;
  background-size:.65em auto, 100%
}

section.produktsuche select::-ms-expand{
  display:none
}

section.produktsuche select:focus::-ms-value{
  background-color:transparent
}

section.produktliste{
  padding-top:50px;
  padding-bottom:50px
}

section.produktliste .prodbox{
  margin-bottom:30px;
  padding-top:20px;
  padding-bottom:20px;
  border-bottom:1px solid #333
}

section.produktliste .col-logo{
  position:relative
}

section.produktliste p.head{
  font-weight:700;
  font-size:20px
}

section.produktliste .btn-deta{
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px
}

section.produktliste .btn-deta:hover{
  background-color:#fff;
  color:#0097f0
}

section.produktform{
  padding-top:50px;
  padding-bottom:50px
}

section.produktform [type="file"]{
  height:0;
  overflow:hidden;
  width:0
}

section.produktform [type="file"]+label{
  background:#0097f0;
  border:none;
  border-radius:5px;
  color:#fff;
  cursor:pointer;
  display:inline-block;
  font-size:inherit;
  outline:none;
  padding:1rem 50px;
  position:relative;
  transition:all 0.3s;
  vertical-align:middle;
  width:100%;
  text-align:center
}

section.produktform [type="file"]+label:hover{
  background-color:#0077bd
}

section.produktform [type="file"]+label.btn-2{
  overflow:hidden
}

section.produktform [type="file"]+label.btn-2:hover{
  background-color:#003757
}

section.produktform [type="file"]+label.btn-2:hover::before{
  right:75%
}

section.produktform input[type='text']:focus{
  border-radius:0
}

section.produktform .form-control{
  background-image:none;
  box-shadow:unset;
  font-size:18px;
  height:38px;
  line-height:1.42857;
  padding:7px 15px;
  width:100%
}

section.produktform textarea{
  height:200px !important
}

section.produktform textarea#produktteaser{
  height:100px !important
}

section.produktform ::-webkit-input-placeholder{
  color:#cccccc
}

section.produktform :-moz-placeholder{
  color:#cccccc
}

section.produktform input:focus:-moz-placeholder{
  color:#cccccc
}

section.produktform ::-moz-placeholder{
  color:#cccccc
}

section.produktform input:focus::-moz-placeholder{
  color:#cccccc
}

section.produktform :-ms-input-placeholder{
  color:#cccccc
}

section.produktform input:focus:-ms-input-placeholder{
  color:#cccccc
}

section.produktform .btn.btn-senden{
  margin-top:16px;
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px
}

section.produktform .btn.btn-senden:hover{
  background-color:#fff;
  color:#0097f0
}

section.produktform .funkyradio div{
  clear:both;
  overflow:hidden
}

section.produktform .funkyradio label{
  width:100%;
  border-radius:3px;
  border:1px solid #e8e8e8;
  font-weight:normal;
  background-color:#fff
}

section.produktform .funkyradio input[type="radio"]:empty,section.produktform .funkyradio input[type="checkbox"]:empty{
  display:none
}

section.produktform .funkyradio input[type="radio"]:empty ~ label,section.produktform .funkyradio input[type="checkbox"]:empty ~ label{
  position:relative;
  padding-left:48px;
  font-size:14px;
  height:50px;
  line-height:15px;
  margin-bottom:1em;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

section.produktform .funkyradio input[type="radio"]:empty ~ label b,section.produktform .funkyradio input[type="checkbox"]:empty ~ label b{
  color:#015f67
}

section.produktform .funkyradio input[type="radio"]:empty ~ label:before,section.produktform .funkyradio input[type="checkbox"]:empty ~ label:before{
  position:absolute;
  display:block;
  top:0;
  bottom:0;
  left:0;
  content:'';
  width:2.5em;
  background:#aaa;
  border-radius:3px 0 0 3px
}

section.produktform .funkyradio input[type="radio"]:hover:not(:checked) ~ label,section.produktform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label{
  color:#888
}

section.produktform .funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,section.produktform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before{
  content:'\2714';
  text-indent:.9em;
  color:#C2C2C2;
  padding-top:15px
}

section.produktform .funkyradio input[type="radio"]:checked ~ label,section.produktform .funkyradio input[type="checkbox"]:checked ~ label{
  color:#777
}

section.produktform .funkyradio input[type="radio"]:checked ~ label:before,section.produktform .funkyradio input[type="checkbox"]:checked ~ label:before{
  content:'\2714';
  text-indent:.9em;
  color:#333;
  background-color:#ccc;
  padding-top:15px
}

section.produktform .funkyradio input[type="radio"]:focus ~ label:before,section.produktform .funkyradio input[type="checkbox"]:focus ~ label:before{
  box-shadow:0 0 0 3px #999
}

section.produktform .funkyradio .funkyradio-info input[type="radio"]:checked ~ label:before,section.produktform .funkyradio .funkyradio-info input[type="checkbox"]:checked ~ label:before{
  color:#fff;
  background-color:#0097f0
}

section.produktform .myprods span{
  display:inline-block;
  width:100%;
  background-color:#e8e8e8;
  padding:10px;
  border-radius:5px;
  margin-bottom:5px
}

section.produktform .myprods span.gesperrt{
  background-color:#f68282;
  cursor:help
}

section.spendenform{
  padding-top:50px;
  padding-bottom:50px
}

section.spendenform input[type='text']:focus{
  border-radius:0
}

section.spendenform .gebdat{
  position:absolute;
  left:-10000px
}

section.spendenform .form-control{
  background-image:none;
  box-shadow:unset;
  font-size:18px;
  height:38px;
  line-height:1.42857;
  padding:7px 15px;
  width:100%
}

section.spendenform ::-webkit-input-placeholder{
  color:#cccccc
}

section.spendenform :-moz-placeholder{
  color:#cccccc
}

section.spendenform input:focus:-moz-placeholder{
  color:#cccccc
}

section.spendenform ::-moz-placeholder{
  color:#cccccc
}

section.spendenform input:focus::-moz-placeholder{
  color:#cccccc
}

section.spendenform :-ms-input-placeholder{
  color:#cccccc
}

section.spendenform input:focus:-ms-input-placeholder{
  color:#cccccc
}

section.spendenform .btn.btn-senden{
  margin-top:16px;
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px
}

section.spendenform .funkyradio div{
  clear:both;
  overflow:hidden
}

section.spendenform .funkyradio label{
  width:100%;
  border-radius:3px;
  border:1px solid #e8e8e8;
  font-weight:normal;
  background-color:#fff
}

section.spendenform .funkyradio input[type="radio"]:empty,section.spendenform .funkyradio input[type="checkbox"]:empty{
  display:none
}

section.spendenform .funkyradio input[type="radio"]:empty ~ label,section.spendenform .funkyradio input[type="checkbox"]:empty ~ label{
  position:relative;
  padding-left:48px;
  font-size:14px;
  height:50px;
  line-height:15px;
  margin-bottom:1em;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

section.spendenform .funkyradio input[type="radio"]:empty ~ label b,section.spendenform .funkyradio input[type="checkbox"]:empty ~ label b{
  color:#015f67
}

section.spendenform .funkyradio input[type="radio"]:empty ~ label:before,section.spendenform .funkyradio input[type="checkbox"]:empty ~ label:before{
  position:absolute;
  display:block;
  top:0;
  bottom:0;
  left:0;
  content:'';
  width:2.5em;
  background:#aaa;
  border-radius:3px 0 0 3px
}

section.spendenform .funkyradio input[type="radio"]:hover:not(:checked) ~ label,section.spendenform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label{
  color:#888
}

section.spendenform .funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,section.spendenform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before{
  content:'\2714';
  text-indent:.9em;
  color:#C2C2C2;
  padding-top:15px
}

section.spendenform .funkyradio input[type="radio"]:checked ~ label,section.spendenform .funkyradio input[type="checkbox"]:checked ~ label{
  color:#777
}

section.spendenform .funkyradio input[type="radio"]:checked ~ label:before,section.spendenform .funkyradio input[type="checkbox"]:checked ~ label:before{
  content:'\2714';
  text-indent:.9em;
  color:#333;
  background-color:#ccc;
  padding-top:15px
}

section.spendenform .funkyradio input[type="radio"]:focus ~ label:before,section.spendenform .funkyradio input[type="checkbox"]:focus ~ label:before{
  box-shadow:0 0 0 3px #999
}

section.spendenform .funkyradio .funkyradio-info input[type="radio"]:checked ~ label:before,section.spendenform .funkyradio .funkyradio-info input[type="checkbox"]:checked ~ label:before{
  color:#fff;
  background-color:#0097f0
}

section.spendenform .spendenbank{
  background-color:#015f67;
  color:#fff;
  border-radius:10px;
  width:100%;
  font-weight:700;
  display:block;
  padding:20px
}

section.spendenform .output{
  font-size:50px;
  color:#0097f0
}

section.steckbriefform{
  padding-top:50px;
  padding-bottom:50px
}

section.steckbriefform [type="file"]{
  height:0;
  overflow:hidden;
  width:0
}

section.steckbriefform [type="file"]+label{
  background:#0097f0;
  border:none;
  border-radius:5px;
  color:#fff;
  cursor:pointer;
  display:inline-block;
  font-size:inherit;
  outline:none;
  padding:1rem 50px;
  position:relative;
  transition:all 0.3s;
  vertical-align:middle;
  width:100%;
  text-align:center
}

section.steckbriefform [type="file"]+label:hover{
  background-color:#0077bd
}

section.steckbriefform [type="file"]+label.btn-2{
  overflow:hidden
}

section.steckbriefform [type="file"]+label.btn-2:hover{
  background-color:#003757
}

section.steckbriefform [type="file"]+label.btn-2:hover::before{
  right:75%
}

section.steckbriefform input[type='text']:focus{
  border-radius:0
}

section.steckbriefform .form-control{
  background-image:none;
  box-shadow:unset;
  font-size:18px;
  height:38px;
  line-height:1.42857;
  padding:7px 15px;
  width:100%
}

section.steckbriefform textarea{
  height:200px !important
}

section.steckbriefform ::-webkit-input-placeholder{
  color:#cccccc
}

section.steckbriefform :-moz-placeholder{
  color:#cccccc
}

section.steckbriefform input:focus:-moz-placeholder{
  color:#cccccc
}

section.steckbriefform ::-moz-placeholder{
  color:#cccccc
}

section.steckbriefform input:focus::-moz-placeholder{
  color:#cccccc
}

section.steckbriefform :-ms-input-placeholder{
  color:#cccccc
}

section.steckbriefform input:focus:-ms-input-placeholder{
  color:#cccccc
}

section.steckbriefform .btn.btn-senden{
  margin-top:16px;
  padding:5px 30px;
  background-color:#0097f0;
  border:1px solid #0097f0;
  color:#fff;
  font-size:16px
}

section.steckbriefform .btn.btn-senden:hover{
  background-color:#fff;
  color:#0097f0
}

section.steckbriefform .funkyradio div{
  clear:both;
  overflow:hidden
}

section.steckbriefform .funkyradio label{
  width:100%;
  border-radius:3px;
  border:1px solid #e8e8e8;
  font-weight:normal;
  background-color:#fff
}

section.steckbriefform .funkyradio input[type="radio"]:empty,section.steckbriefform .funkyradio input[type="checkbox"]:empty{
  display:none
}

section.steckbriefform .funkyradio input[type="radio"]:empty ~ label,section.steckbriefform .funkyradio input[type="checkbox"]:empty ~ label{
  position:relative;
  padding-left:48px;
  font-size:14px;
  height:50px;
  line-height:15px;
  margin-bottom:1em;
  cursor:pointer;
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

section.steckbriefform .funkyradio input[type="radio"]:empty ~ label b,section.steckbriefform .funkyradio input[type="checkbox"]:empty ~ label b{
  color:#015f67
}

section.steckbriefform .funkyradio input[type="radio"]:empty ~ label:before,section.steckbriefform .funkyradio input[type="checkbox"]:empty ~ label:before{
  position:absolute;
  display:block;
  top:0;
  bottom:0;
  left:0;
  content:'';
  width:2.5em;
  background:#aaa;
  border-radius:3px 0 0 3px
}

section.steckbriefform .funkyradio input[type="radio"]:hover:not(:checked) ~ label,section.steckbriefform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label{
  color:#888
}

section.steckbriefform .funkyradio input[type="radio"]:hover:not(:checked) ~ label:before,section.steckbriefform .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before{
  content:'\2714';
  text-indent:.9em;
  color:#C2C2C2;
  padding-top:15px
}

section.steckbriefform .funkyradio input[type="radio"]:checked ~ label,section.steckbriefform .funkyradio input[type="checkbox"]:checked ~ label{
  color:#777
}

section.steckbriefform .funkyradio input[type="radio"]:checked ~ label:before,section.steckbriefform .funkyradio input[type="checkbox"]:checked ~ label:before{
  content:'\2714';
  text-indent:.9em;
  color:#333;
  background-color:#ccc;
  padding-top:15px
}

section.steckbriefform .funkyradio input[type="radio"]:focus ~ label:before,section.steckbriefform .funkyradio input[type="checkbox"]:focus ~ label:before{
  box-shadow:0 0 0 3px #999
}

section.steckbriefform .funkyradio .funkyradio-info input[type="radio"]:checked ~ label:before,section.steckbriefform .funkyradio .funkyradio-info input[type="checkbox"]:checked ~ label:before{
  color:#fff;
  background-color:#0097f0
}
